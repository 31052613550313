import { createFeatureSelector, createSelector } from '@ngrx/store';
import * as fromUsers from './users.reducer';
import { HomeSelectors } from '../../store';

export const selectUsersState = createFeatureSelector<fromUsers.State>(
    fromUsers.usersFeatureKey
);

export const selectTableVM = createSelector(selectUsersState, state => state.tableVM);
export const selectIsInvitingUser = createSelector(selectUsersState, state => state.isInvitingUser);
export const selectIsRemovingUser = createSelector(selectUsersState, state => state.isRemovingUser);
export const selectIsResendingInvite = createSelector(selectUsersState, state => state.isResendingInvite);
export const selectIsLoadingInvitations = createSelector(selectUsersState, state => state.isLoadingInvitations);
export const selectUsersLoadingIndicatorShown = createSelector(selectIsRemovingUser, selectIsResendingInvite, selectIsLoadingInvitations, (isRemovingUser, isResendingInvite, isLoadingInvitations) => isRemovingUser || isResendingInvite || isLoadingInvitations);
export const selectIsUserInvitationMenuOpen = createSelector(selectUsersState, state => state.isUserInvitationMenuOpened);
export const selectIsUserInvitationUploadMenuOpen = createSelector(selectUsersState, state => state.isUserInvitationUploadMenuOpened);
export const selectFilter = createSelector(selectUsersState, state => state.filter);

export const selectPagination = createSelector(selectUsersState, state => state.pagination)

export const selectDataSourceParameters = createSelector(selectFilter, selectPagination, (dateFilter, pagination) =>
({
  text: dateFilter.text,  
  pageSize: pagination.pageSize,
  pageIndex: pagination.pageIndex,
  previousPageIndex: pagination.previousPageIndex
})
);

export const selectPaginatorLength = createSelector(selectUsersState, state => state.totalRowsCount);
export const selectPageIndex = createSelector(selectUsersState, state => state.pagination.pageIndex);
export const selectPageSize = createSelector(selectUsersState, state => state.pagination.pageSize);

export const selectSelfInvitationEmailDomains = createSelector(HomeSelectors.selectBusiness, business => business?.selfInvitationEmailDomains?.length > 0 ? business?.selfInvitationEmailDomains : null);

export const selectIsNoActiveUsersContainerShown = createSelector(selectTableVM, selectFilter, selectIsLoadingInvitations, (tableVM, filter, isLoadingInvitations) => tableVM.rows?.length === 0 && filter.text.length === 0 && !isLoadingInvitations);
export const selectIsNoDataContainerShown = createSelector(selectTableVM, selectFilter, selectIsLoadingInvitations, (tableVM, filter, isLoadingInvitations) => tableVM.rows?.length === 0 && filter.text.length > 0 && !isLoadingInvitations);