import { createFeatureSelector, createSelector } from '@ngrx/store';
import * as fromRequestRide from './request-ride.reducer';
import { HomeSelectors } from '../../store';
import { MetaOp } from 'src/app/shared/models/firestore.model';
import { AuthSelectors } from 'src/app/auth/store';

export const selectRequestRideState = createFeatureSelector<fromRequestRide.State>(
  fromRequestRide.requestRideFeatureKey
);

export const selectBusinessAggregate = createSelector(HomeSelectors.selectBusinessAggregate, aggregate => aggregate);
export const selectIsAgent = createSelector(AuthSelectors.selectIsAgent, isAgent => isAgent);
export const selectRequestRideEnabled = createSelector(selectIsAgent, selectBusinessAggregate, (isAgent, aggregate) => isAgent ? true : aggregate?.agents?.filter(agent => agent.meta_op !== MetaOp.DELETE).length > 0);
