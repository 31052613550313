import { createAction, props } from '@ngrx/store';
import { UsersTableVM } from './users.reducer';

export const UsersComponentInit = createAction(
    '[Users] Users component init'
);

export const UsersComponentDestroyed = createAction(
    '[Users] Users component destroyed'
);

export const userInvitationClosed = createAction(
    '[Users] User invitation closed'
);

export const inviteUserClicked = createAction(
    '[Users] Invite user clicked',
    props<{ email: string }>()
);

export const inviteUserFinished = createAction(
    '[Users] Invite user finished'
);

export const inviteUserSuccess = createAction(
    '[Users] Invite user success'
);

export const resendInvitationClicked = createAction(
    '[Users] Resend invitation clicked',
    props<{ invitationId: string }>()
);

export const resendInvitationFinished = createAction(
    '[Users] Resend invitation finished'
);

export const removeUserClicked = createAction(
    '[Users] Remove user clicked',
    props<{ invitationId: string, businessProfileId: string, email: string }>()
);

export const removeUserInvitationConfirmed = createAction(
    '[Users] Remove user confirmed',
    props<{ invitationId: string }>()
);

export const removeRiderBusinessProfileConfirmed = createAction(
    '[Users] Remove rider business profile confirmed',
    props<{ inviationId: string, businessProfileId: string }>()
);

export const removeUserFinished = createAction(
    '[Users] Remove user finished',
);

export const removeUserSuccess = createAction(
    '[Users] Remove user success',
);

export const userInvitationMenuOpenedChanged = createAction(
    '[Users] User invitation menu opened changed',
    props<{ opened: boolean }>()
);

export const userInvitationUploadMenuOpenedChanged = createAction(
    '[Users] User invitation upload menu opened changed',
    props<{ opened: boolean }>()
);

export const dataChanged = createAction(
    '[Users] Users data changed',
    props<{ tableVM: UsersTableVM, totalCount: number; }>()
);
  
export const filterChanged = createAction(
    '[Users] Filter changed',
    props<{ filter: { text: string } }>()
);

export const pageEvent = createAction(
    '[Users] Paging changed',
    props<{ pageSize: number; pageIndex: number; previousPageIndex?: number }>()
);

export const requestAccessClicked = createAction(
    '[Users] Request access clicked'
);

export const bulkInviteClicked = createAction(
    '[Users] Bulk invite clicked'
);