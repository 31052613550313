/* eslint-disable  @typescript-eslint/no-empty-interface */
import { createReducer, on } from '@ngrx/store';

export const requestRideFeatureKey = 'requestRide';

export interface State {
}

export const initialState: State = {
};

export const reducer = createReducer(
  initialState,
);
